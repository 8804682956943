import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/Seo/seo"
import HomeIntro from "../components/home-intro/team-intro";
import StaticPage from '../components/property-details/static-content'
import PropertyRelated from '../components/property-related/featured-property'
import OtherStaffs from '../components/property-videos/other-staffs'
import {graphql } from 'gatsby'

const TeamDetails = (props) => {
 const GQLPage    = props.data.glstrapi?.team;
 const GQLModules = props.GQLModules;
 var metadesc = "Get to know about "+GQLPage.Name+" here. Contact one of our expert estate agents for assistance in your property journey.";
 return (
     
  <Layout pageclass="details-team">
    <div className="team-details-wrapper">
    <SEO title={GQLPage.Meta_Title?GQLPage.Meta_Title:GQLPage.Name+' '+GQLPage.Designation} description={GQLPage.Meta_Description?GQLPage.Meta_Description:metadesc} />
    <HomeIntro layout={GQLPage.Layout} title={GQLPage.Name} content={GQLPage.Designation} image={GQLPage.Staff_Image} imagetransforms={GQLPage.imagetransforms} id={GQLPage.id} phone={GQLPage.Phone} twitter={GQLPage.Twitter_Link} linkedin={GQLPage.LinkedIn_Link}/>
    <StaticPage content={GQLPage.Bio} title={GQLPage.Name} image={GQLPage.Staff_Image}  imagetransforms={GQLPage.imagetransforms} id={GQLPage.id} />
    {/* <PropertyRelated /> */}
    <OtherStaffs url={GQLPage.URL}/>
    </div>
  </Layout>
 )
}
export default TeamDetails;


export const teamdetailQuery = graphql`
query TeamsQuery($id: ID!) {
  glstrapi {
    team(id: $id) {  
        id
        Name
        Meta_Title
        Meta_Description
        URL
        Designation
        Email
        Phone
        Twitter_Link
        LinkedIn_Link
        Bio
        Video_URL
        Staff_Image{
          url
          alternativeText
        }
        imagetransforms 
    }
}
}`

